/* eslint-disable react-hooks/exhaustive-deps */
import {MetroTable, MetroTableProps} from '../MetroTable'
import {MetroPagination, useFetch, usePagination} from '../index'
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {deleteNegativeValueInObject, handlerFilterDataMetroTableServerside} from '../../utils'

interface MetroTableServerSideProps extends MetroTableProps<any> {
  data?: undefined
  prefix?: string
  apiFunction: (data: any) => Promise<any>
  paramsOrData?: any
}
const MetroTableServerSide = forwardRef((props: MetroTableServerSideProps, ref) => {
  const getData = useFetch({apiFunction: props.apiFunction})
  const [sortData, setSortData] = useState({})
  const [filterData, setFilterData] = useState({})
  const {page, size, setPage} = usePagination({
    initialPage: props.paramsOrData?.page || 0, prefix: props.prefix})
  
  const fetchData = () => {
    getData.fetch({
      ...props.paramsOrData,
      ...sortData,
      ...filterData,
      page: props.paramsOrData?.page || page,
      limit: props.paramsOrData?.limit || props.pageSize || 1000
    })
  }
  useEffect(() => {
    if (page < 1) {
      setTimeout(() => {
        setPage(1)
      }, 300)
    }

  }, [])

  useEffect(() => {
    if (page) {
      fetchData()
    }
  }, [page, size, sortData, filterData, props.paramsOrData])
  useImperativeHandle(ref, () => ({
    refreshData() {
      fetchData()
    },
  }))
  
  return (
    <>
      <MetroTable
        {...props}
        onSortChange={(sortData :any) => {
          setSortData(deleteNegativeValueInObject(sortData))
        }}
        onFilterChange={(filterData: any) => {
          const takeFilter = handlerFilterDataMetroTableServerside(deleteNegativeValueInObject(filterData))
          setPage(1)
          setFilterData(takeFilter)
        }}
        loading={getData.isLoading}
        data={getData.list}
        // pagination={null}
      />
      {/* <MetroPagination
        className={'my-5'}
        current={page}
        total={getData.response?.data?.pagination?.total || 0}
        position={'right'}
        onChange={(page, pageSize) => {
          setPage(page)
        }}
      /> */}
    </>
  )
})
export {MetroTableServerSide}